
import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

export default function Kategoria({ data }) {
  const kategoria = data.allWpCategory.nodes[0];
  const compare = (a,b) => {
    if (a.title < b.title)
       return -1;
    if (a.title > b.title)
      return 1;
    return 0;
  }
  const posts = data.allWpPost.nodes;
  let char = '';
  posts.sort(compare);
  return (
    <Layout>
      <div className="kategoria">
        <h1 style={{
          fontSize: '2.3em',
          marginBottom: '1em'
        }}>Kategoria: {kategoria.name}</h1>
        <h2>{posts.length} artikulu</h2>
        <ul className="posts">
        {posts.map(post => {
          let showFirstChar = false;
          const current = post.title[0].toUpperCase();
          if(char !== current) {
            char = current;
            showFirstChar = true;
          }
          return (
            <>
                <li>
                {showFirstChar && (
                  <>
                  <h3>{char}</h3>
                  <hr />
                  </>
                )}
                  <Link to={`/${post.slug}`}>{post.title}</Link></li>
            </>
        )})}
        </ul>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    allWpCategory(limit: 99999, filter: { slug: { eq: $slug } }) {
        nodes {
            id
            uri
            slug
            name
          }
    }
    allWpPost(
      limit: 99999
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
      sort: { fields: title, order: ASC }
     ) {
      nodes {
        title
        slug
        date(formatString: "MMMM DD, YYYY")
      }
     }
  }`